import { IonContent, IonHeader, IonPage, IonButtons,IonBackButton, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab12.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';

const Tab12: React.FC = () => {
  const stato = UIStore.useState(s => s);
  var pag = "/tab13"
  var frase ="Comunica al DSR:"
  var frase2 = "La suddivisione del CRASH in settori"
  var frase3 = "Di radunare le squadre sopraggiunte"
  var frase4 = "Di istruire le squadre su settori di intervento, uso della scheda maxi, triage START"
  var frase5 = "Di avviare squadre di recupero nei vari settori del CRASH e nelle aree di raccolta solo dopo l'ok dei VVF"
  var frase6 = null
  switch (stato.ruolo) {
    case 1:
      break;
    case 2:
      // code for case 2
      break;
    case 4: //DPMA
      // code for case 4
      break;
    case 5: // Soccorritore MSB
      frase = "Attendi l'ok del DSR prima di avvicinarti al CRASH"
      frase2 = "Recati nel settore che ti è stato assegnato"
      frase3 = "Esegui il triage START"
      frase4 = "Tieni conto dei pazienti visti"
      frase5 = "Riferisci al DSR dei pazienti da te triagiati"
      frase6 = "Non recarti su altri settori"
      pag = "/start?fine=1"
      break;
    default:
        
      break;
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Avvia Soccorrsi</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Avvia i Soccorsi</h1>
          <h3 style={{ textAlign: 'left', margin: '32px'}}>{frase}</h3>
          <h2>{frase2}</h2>
          <h2>{frase3}</h2>
          <h2>{frase4}</h2>
          <h2>{frase5}</h2>
          {frase6 && <h2>{frase6}</h2>}
          <IonButton fill="clear" size="large" routerLink={pag}>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab12;
