import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab18.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab18: React.FC = () => {
  const stato = UIStore.useState(s => s);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Nel PMA</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Nel PMA</h1>
          <IonButton shape="round" size="large" color={stato.triageing ? 'success' : 'light'} routerLink='/smart'>1: Triage in ingresso SMART</IonButton>
          <IonButton shape="round" size="large" color={stato.alsnec ? 'success' : 'light'} routerLink='/tab19'>2: Conferma n° ALS necessarie</IonButton>
          <IonButton shape="round" size="large" color={stato.stabord ? 'success' : 'light'}  routerLink='/tab20'>3: Stabilizza secondo questo ordine</IonButton>
          <IonButton shape="round" size="large" color={stato.evacpaz ? 'success' : 'light'} routerLink='/tab21'>4: Exit Triage Ed Evacua i Pazienti</IonButton>
          {
              stato.ruolo == 4 ? ( 
                <IonButton fill="clear" size="large" routerLink='/tab22'>Continua<IonIcon slot="end" icon={chevronForward}></IonIcon></IonButton>
              ) : (
                <IonButton fill="clear" size="large" routerLink='/tab4'>Continua<IonIcon slot="end" icon={chevronForward}></IonIcon></IonButton>
              )
          }
         
            

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab18;
