import { IonContent, IonHeader, IonPage, IonButtons, IonBackButton, IonTitle, IonToolbar,IonButton,IonItem,IonLabel,IonInput,IonTextarea,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import { chevronForward} from 'ionicons/icons';
import './Tab5.css';
import { caretBack} from 'ionicons/icons';
import { UIStore } from "./UIstore";

const Tab5: React.FC = () => {
  const stato = UIStore.useState(s => s);
  var pag = "/tab6";
  switch (stato.ruolo) {
    case 1:
      // code for case 1
      break;
    case 2:
      // code for case 2
      break;
    case 4: //DPMA
      pag = "/tab9";
      // code for case 4
      break;
    case 5: // Soccorritore MSB
      pag = "/tab9";
      break;
    default:
        
      break;
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Raccolta Info da CO118</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Raccolta Info da CO118</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <IonItem>
            <IonLabel position="stacked">
              <h2>ESATTA LOCALIZZAZIONE</h2>
            </IonLabel>
            <IonInput placeholder="Vie di accesso, Viabilità"></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <h2>TIPO EVENTO</h2>
            </IonLabel>
            <IonInput  placeholder="Attentato, Incidente, Calamità"></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <h2>RISCHI SPECIFICI</h2>
            </IonLabel>
            <IonInput placeholder="Gas, Sostanze, Armi, Traffico"></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <h2>NUMERO FERITI STIMATO</h2>
            </IonLabel>
            <IonInput></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <h2>MEZZI DI SUPPORTO 118 ALLERTATI</h2>
            </IonLabel>
            <IonInput></IonInput>
          </IonItem>
          <IonButton fill="clear" size="large" routerLink={pag}>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab5;
