import { IonContent, IonHeader, IonButtons,IonBackButton, IonPage,IonToggle , IonTitle, IonToolbar,IonButton,IonItem,IonLabel,IonInput,IonTextarea,IonIcon, IonRadio, IonRadioGroup } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import { chevronForward} from 'ionicons/icons';
import './Tab10.css';
import { caretBack} from 'ionicons/icons';
import { UIStore } from "./UIstore";

const Tab10: React.FC = () => {
  const stato = UIStore.useState(s => s);

  function dimens() {
    UIStore.update(s => {
      s.dimevento = true;
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Ricognizione e Dimensionamento</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <IonItem>
            <IonLabel><h2>M: Maxiemergenza confermata?</h2></IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked"><h2>E: Conferma sede esatta</h2></IonLabel>
            <IonToggle slot="end"></IonToggle>
            <IonTextarea></IonTextarea>            
          </IonItem>
          <IonItem>
            <IonLabel position="stacked"><h2>T: Conferma tipo evento</h2></IonLabel>
            <IonToggle slot="end"></IonToggle>
            <IonInput placeholder="Attentato, Incidente, Calamità"></IonInput>      
          </IonItem>
          <IonItem>
            <IonLabel position="stacked"><h2>H: Eventuali Pericoli</h2></IonLabel>
            <IonToggle slot="end"></IonToggle>
            <IonTextarea placeholder="Gas, Sostanze, Armi"></IonTextarea>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked"><h2>A: Conferma vie accesso</h2></IonLabel>
            <IonToggle slot="end"></IonToggle>
            <IonInput></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked"><h2>N: Conferma stima n° feriti</h2></IonLabel>
            <IonToggle slot="end"></IonToggle>
            <IonInput></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked"><h2>E: Richiedi soccorsi specifici</h2></IonLabel>
            <IonToggle slot="end"></IonToggle>
            <IonInput placeholder="SAR, NBCR"></IonInput>
          </IonItem>
          <IonButton fill="clear" onClick={() => dimens()} size="large" routerLink='/tab8'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab10;
