import { IonContent, IonHeader, IonButtons, IonBackButton,IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab17.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab17: React.FC = () => {
  const stato = UIStore.useState(s => s);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Attivato PMA</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Se Attivato PMA</h1>
          <h2>Tutti i pazienti inclusi i verdi passano per il pma</h2>
          <h2>Il DPMA aggiorna il DSS costantemente sul numero di gialli e rossi</h2>
          <IonButton fill="clear" size="large" routerLink='/tab18'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab17;
