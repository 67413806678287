import React, { useState, useEffect } from 'react';
import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonItem,IonLabel,IonInput,IonTextarea,IonIcon, IonRadio, IonRadioGroup } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import { chevronForward} from 'ionicons/icons';
import './Tab18.css';
import { caretBack} from 'ionicons/icons';
import { UIStore } from "./UIstore";

const Tab18: React.FC = () => {
  const stato = UIStore.useState(s => s);
  const [gialli, setGialli] = useState<number>(0);
  const [rossi, setRossi] = useState<number>(0);
  const [nals, setNals] = useState<number>(0);

  function dimens() {
    UIStore.update(s => {
      s.alsnec= true;
    });
  }

  React.useEffect(() => {
    const sum = +gialli + +rossi;
    setNals(sum / 4);
  }, [gialli, rossi]);
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Calcolo ALS</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Identifica il collega DPMA</h1>
          <h3>Raccomanda al DPMA di:</h3>
          <h2>Usare triage smart all'ingresso del PMA</h2>
          <h2>Aggiornare il DSS costantemente sul numero di gialli e rossi presenti nel PMA</h2>
          <h2>Aggiornare la CO118 su richiesta di nuove MSA</h2>
          <h1>Calcolo ALS da Richiedere</h1>
          <IonItem>
            <IonLabel>Codici Gialli</IonLabel>
            <IonInput  type="number" onIonInput={(e: any) => setGialli(parseInt(e.target.value))}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Codici Rossi</IonLabel>
            <IonInput  type="number" onIonInput={(e: any) => setRossi(parseInt(e.target.value))}></IonInput>
          </IonItem>
          <h3>N° ALS: {nals}</h3>
          <IonButton fill="clear" size="large" onClick={() => dimens()} routerLink='/tab18'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab18;
