import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar, IonButton, IonCheckbox,IonList,IonItem,IonLabel,IonIcon,IonInput,IonCol,IonSelect,IonSelectOption,IonRow,IonGrid} from '@ionic/react';
import './ruolo.css';
import { UIStore } from "./UIstore";
import { chevronForward} from 'ionicons/icons';
import { caretBack} from 'ionicons/icons';
const Assegna: React.FC = () => {
  const stato = UIStore.useState(s => s);
  console.log(stato);
  function dimens() {
    UIStore.update(s => {
      s.target = true;
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Assegna</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Assegna</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Assegna</h1>
          <h2>Ad ogni squadra il proprio settore</h2>
          <h2>Autorizza le squadre a recarsi sul settore</h2>
         

        <IonButton fill="clear" size="large" onClick={() => dimens()} routerLink='/tab4'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Assegna;