import { IonContent, IonHeader, IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab9.css';
import { chevronForward} from 'ionicons/icons';
import { caretBack} from 'ionicons/icons';
import { UIStore } from "./UIstore"; 

const Tab9: React.FC = () => {

  const stato = UIStore.useState(s => s); 
  var pag = "/pianificazione";
  var frase = "Indossa e fai indossare DPI (Divisa,Scarpe AI,Caschetto,Mascherina)"
  var frase2 ="Indossa e fai indossare la casacca identificativa (DSS,DSR,DTR) sopra la divisa"
  var frase3 ="Attendi l'ok per la scena sicura dal dss"
  var frase4 = null
  switch (stato.ruolo) {
    case 1:
      frase3 ="Verifica la sicurezza della scena con Vigili del Fuoco e Forze dell'Ordine, escludere subito la possibilità di un attentato"
      pag = "/tab10";
      break;
    case 2:
      // code for case 2
      break;
    case 4: //DPMA
    pag = "/tab17";
      // code for case 4
      break;
    case 5: // Soccorritore MSB
      pag = "/tab12";
      frase = "Indossa e fai indossare tutti DPI (Divisa,Scarpe,AI,Caschetto,Mascherina)"
      frase2 = "Non prendere iniziativa"
      frase3 = "Rispetta la catena di comando"
      frase4 = "Non parlare con i media"
      break;
    default:
        
      break;
  }
  let routerlink;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sicurezza</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Sicurezza</h1>
          <h2>{frase}</h2>
          <h2>{frase2}</h2>
          <h2>{frase3}</h2>
          {frase4 && <h2>{frase4}</h2>}
          <IonButton fill="clear" size="large" routerLink={pag}>
                Continua
          <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

          
         

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab9;
