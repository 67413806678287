import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton,IonButtons,IonBackButton, IonCheckbox,IonList,IonItem,IonLabel,IonIcon,IonInput,IonCol,IonSelect,IonSelectOption,IonRow,IonGrid} from '@ionic/react';
import './ruolo.css';
import { UIStore } from "./UIstore";
import { chevronForward} from 'ionicons/icons';
import { caretBack} from 'ionicons/icons';
const Elenco: React.FC = () => {
  const stato = UIStore.useState(s => s);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Elenco</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Elenco</IonTitle>
            <IonButtons slot="start">
              <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          { stato.ruolo == 2 ? <h1>Elenco squadre sopraggiunte</h1> : <h1>Elenco mezzi sopraggiunti</h1>}
          
          
          { stato.ruolo == 2 ? 
           <IonGrid>
           {[...Array(15)].map((e, i) => {
                 return <IonRow>
                 <IonCol size="auto">
                 <IonItem>
                   <IonInput placeholder="Squadra"></IonInput>
                 </IonItem>
                 </IonCol>
                 <IonCol size="auto">
                 <IonItem>
                   <IonSelect placeholder="Tipo">
                     <IonSelectOption value="MSA">MSA</IonSelectOption>
                     <IonSelectOption value="MSI">MSI</IonSelectOption>
                     <IonSelectOption value="MSB">MSB</IonSelectOption>
                   </IonSelect>
                 </IonItem>
                 </IonCol>
               </IonRow>
               })}
             
           </IonGrid>
          : 
          <IonGrid>
          {[...Array(15)].map((e, i) => {
                return <IonRow>
                <IonCol size="4">
                <IonItem>
                  <IonInput placeholder="ID"></IonInput>
                </IonItem>
                </IonCol>
                <IonCol size="3">
                  <IonItem>
                    <IonSelect placeholder="Tipo">
                      <IonSelectOption value="MSA">MSA</IonSelectOption>
                      <IonSelectOption value="MSI">MSI</IonSelectOption>
                      <IonSelectOption value="MSB">MSB</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="2">
                  <IonItem>
                    <IonInput type="number" placeholder="N° Membri"></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="2">
                  <IonItem>
                    <IonSelect placeholder="Tipo">
                        <IonSelectOption value="MSA">In Arrivo</IonSelectOption>
                        <IonSelectOption value="MSI">Sul Crash</IonSelectOption>
                        <IonSelectOption value="MSB">Ripartita per PS</IonSelectOption>
                      </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              })}
            
          </IonGrid>}
         

        <IonButton fill="clear" size="large" routerLink='/soccorsi'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Elenco;