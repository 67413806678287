import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab15.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab15: React.FC = () => {
  const stato = UIStore.useState(s => s);
  function dimens() {
    UIStore.update(s => {
      s.areeracc= true;
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Aree di Raccolta</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Identificazione aree di raccolta</h1>
          <h2>Area Verdi</h2>
          <h2>Area Gialli</h2>
          <h2>Area rossi</h2>
          <h2>Area Salme</h2>
          <h2>Area Sosta Mezzi</h2>
          <IonButton fill="clear" size="large" onClick={() => dimens()} routerLink='/tab8'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab15;
