import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar, IonButton, IonCheckbox,IonList,IonItem,IonLabel,IonRadioGroup,IonRadio,IonIcon,IonInput,IonCol,IonSelect,IonSelectOption,IonRow,IonGrid} from '@ionic/react';
import './ruolo.css';
import { UIStore } from "./UIstore";
import { chevronForward} from 'ionicons/icons';
import { caretBack} from 'ionicons/icons';
const Soccorsi: React.FC = () => {
  const stato = UIStore.useState(s => s);
  console.log(stato);
  function dimens() {
    UIStore.update(s => {
      s.termine = true;
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>AVVIA I SOCCORSI</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">AVVIA I SOCCORSI</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          { stato.ruolo == 2 ?  
          <div className="">
          <h1>Attendi che il DSS:</h1>
          <h2>Dichiari la scena sicura</h2>
          <h2>Informi sulla suddivisione in settori</h2>
          <IonButton fill="clear" size="large" routerLink='/assegna'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
        </IonButton>
          </div>
          : 
          <div className="">
           <IonList>
            <IonRadioGroup>
            <IonLabel>PMA attivato?</IonLabel>
              <IonItem>
                <IonLabel>Si</IonLabel>
                <IonRadio slot="end" value="grapes"></IonRadio>
              </IonItem>

              <IonItem>
                <IonLabel>No</IonLabel>
                <IonRadio slot="end" value="strawberries"></IonRadio>
              </IonItem>
            </IonRadioGroup>
          </IonList>
          <h2>Collabora alla scelta area PMA</h2>
          <h2>Verifica presenza vie di accesso PMA</h2>
          <IonButton fill="clear" onClick={() => dimens()} size="large" routerLink='/tab4'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>
          </div>
          
          }
         
         

       
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Soccorsi;