import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButton,IonButtons,IonBackButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab3.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';

const Tab3: React.FC = () => {
  const stato = UIStore.useState(s => s);

  var pag = "/tab4";
  var frase ="Ti stai recando in una sospetta maxiemergenza"
  var frase2 ="Il tuo ruolo non è soccorrere ma dimensionare l’evento e coordinare."
  var frase3 ="Segui in ordine questi passaggi ed evidenzia in verde quando lo hai eseguito."
  switch (stato.ruolo) {
    case 1:
      var pag = "/tab4";
      var frase = "Ti stai recando in una maxiemergenza come prima ALS"
      // code for case 1
      break;
    case 2:
      var pag = "/tab4";
      var frase = "Ti stai recando in una maxiemergenza come prima ALS"
      // code for case 2
      break;
    case 4: //DPMA
      pag = "/tab5";
      frase ="Ti stai recando su una maxi emergenza come direttore del PMA"
      frase2 = "Il tuo compito è prendere istruzioni dal DSS, coordinare le squadre che lavorarenno nel pma secondo l'ordine stabilito per triage, dare priorità di evacuazione"
      frase3 = "Segui in ordine i passaggi di questa guida"
      // code for case 4
      break;
    case 5: // Soccorritore MSB
      pag = "/tab5";
      frase = "Ti stai recando su una maxi emergenza come soccorritore"
      frase2 = "Il tuo compito è prendere istruzioni dal DSR, Fare triage START sul CRASH solo dopo l'ok del DSR, Collaborare al montaggio del PMA"
      frase3 = "Segui in ordine i passaggi di questa guida"
      break;
    default:
        
      break;
  }
    
  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Inizio</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Inizio</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h2>{frase}</h2>
          <h2>{frase2}</h2>
          <h2>{frase3}</h2>
          <IonButton fill="clear" size="large" routerLink={pag}>Continua<IonIcon slot="end" icon={chevronForward}></IonIcon></IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
