import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab22.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab22: React.FC = () => {
  const stato = UIStore.useState(s => s);

  function dimens() {
    UIStore.update(s => {
      s.termine = true;
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Termine Evento</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Al termine dell'evento</h1>
          <IonButton shape="round" size="large" color="light" disabled={true}>Crea Report Evento</IonButton>
          { stato.ruolo == 1 ? (
            <h2>Esegui le constatazioni di decesso(prima informa i parenti, poi la stampa. La stampa non può e non deve incontrare i parenti)</h2>
          ) : null }
            { stato.ruolo == 1 ? (
            <h2>Dichiarare la fine delle proprie operazioni</h2>
          ) : null }
          <h2>Esegui il defusing con gli operatori</h2>
          <IonButton fill="clear" size="large"  onClick={() => dimens()} routerLink='/'>
            Termina
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab22;
