import { IonContent, IonHeader, IonPage, IonTitle,IonButtons,IonBackButton, IonToolbar, IonButton} from '@ionic/react';
import './ruolo.css';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab4: React.FC = () => {
  const stato = UIStore.useState(s => s);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Stato</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Stato</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <IonButton shape="round" expand="full" size="large" color={stato.andata ? 'success' : 'light'} routerLink='/tab5'>1: In Andata</IonButton>
          {
              stato.ruolo == 1 ? ( 
                <IonButton shape="round" expand="full" size="large" color={stato.dimevento && stato.pcoa && stato.attpma && stato.areeracc ? 'success' : 'light'} routerLink='/tab8'>2: Sul Target</IonButton>
              ) : (
                <IonButton shape="round" expand="full" size="large" color={stato.target ? 'success' : 'light'} routerLink='/tab9'>2: Sul Target</IonButton>
              )
          }
          <IonButton shape="round" expand="full" size="large" color={stato.termine ? 'success' : 'light'} routerLink='/tab22'>3: Al Termine</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab4;