import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar, IonButton,IonCard,IonCardTitle,IonCardSubtitle,IonCardHeader,IonCardContent,IonIcon } from '@ionic/react';
import { UIStore } from "./UIstore";
import { earth,pulse,people } from 'ionicons/icons';
import { caretBack} from 'ionicons/icons';
const Ruolo: React.FC = () => {
  const ruolo = UIStore.useState(s => s.ruolo);

  

  function cambiaruolo(idruolo: number) {
    UIStore.update(s => {
      s.ruolo = 0;
      s.andata= false;
      s.target= false;
      s.termine= false;
      s.dimevento= false;
      s.pcoa= false;
      s.attpma= false;
      s.areeracc= false;
      s.triageing= false;
      s.alsnec= false;
      s.stabord= false;
      s.evacpaz= false;
      s.gestscena= false
    });
    
    UIStore.update(s => {
      s.ruolo = idruolo;
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Seleziona il tuo ruolo</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Seleziona il tuo ruolo</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <IonButton shape="round" size="large" onClick={() => cambiaruolo(1)} routerLink='/tab3'>Medico MSA</IonButton>
          <IonButton shape="round" size="large" onClick={() => cambiaruolo(2)} routerLink='/tab3'>Infermiere MSA</IonButton>
          <IonButton shape="round" size="large" onClick={() => cambiaruolo(3)} routerLink='/tab3'>Autista MSA</IonButton>
          <IonButton shape="round" size="large" onClick={() => cambiaruolo(4)} routerLink='/tab3'>Medico DPMA</IonButton>
          <IonButton shape="round" size="large" onClick={() => cambiaruolo(5)} routerLink='/tab3'>Soccorritore MSB</IonButton>
            <IonCard  color="primary" routerLink='/ruoli'>
            <img  src="/assets/img/maxi.jpeg" />
              <IonCardHeader>
                <IonCardTitle><IonIcon icon={people} size="large" color="primary"></IonIcon></IonCardTitle>
                  <IonCardSubtitle>Spiegazione Ruoli</IonCardSubtitle>
                </IonCardHeader>
          </IonCard>
         {/* <IonButton shape="round" size="large" onClick={() => cambiaruolo(4)} routerLink='/tab3'>Soccorritore MSB</IonButton>*/}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Ruolo;