import { IonContent, IonHeader, IonButtons, IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab6.css';
import { chevronForward} from 'ionicons/icons';
import { caretBack} from 'ionicons/icons';
import { UIStore } from "./UIstore"; 

const Tab6: React.FC = () => {
  const stato = UIStore.useState(s => s); 
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
            <IonTitle>Assegnazione Ruoli</IonTitle>
            <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
              <IonTitle>Assegnazione Ruoli</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
        {
            stato.ruolo == 1 ? ( 
              <h1>Assegna ora i ruoli al tuo equipaggio</h1>
            ) : (
              <h1>Ricevi i ruoli dal DSS (MEDICO)</h1>
            )
          }
          <h2>Medico = DSS Direttore Soccorsi Sanitari<h3 className="medico">Casacca Gialla</h3></h2>
          <h2>Infermiere = DSR Direttore Squadre di Ricerca <h3 className="inf">Casacca Rossa</h3></h2>
          <h2>Autista = DTR Direttore Trasporti e comunicazioni Radio <h3 className="aut">Casacca Blu</h3></h2>

          <p>I ruoli indicati sono svolti dall'equipaggio della prima MSA intervenuta fino a nuova indicazione.</p>
          <p>La CO 118 invierà sul posto un medico esperto</p>
          <IonButton fill="clear" size="large" routerLink='/tab7'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab6;
