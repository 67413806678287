import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab15.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab15B: React.FC = () => {
  const stato = UIStore.useState(s => s);
  function dimens() {
    UIStore.update(s => {
      s.gestscena= true;
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Gestione Scena</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>All'arrivo di altri mezzi di soccorso, insieme al DSR:</h1>
          <h2>Assegna ruoli e funzioni agli equipaggi</h2>
          <h2>Assegna il personale alle diverse aree di soccorso e valuta la necessità di sostituzione</h2>
          <h2>Informati sullo svolgimento delle operazioni di soccorso</h2>
          <h2>Controlla la necessità di rifornimento materiali/attrezzature</h2>
          <h2>Se necessario tieni rapporti con la stampa</h2>
          <IonButton fill="clear" size="large" onClick={() => dimens()} routerLink='/tab8'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab15B;
