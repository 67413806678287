import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButton,IonIcon, IonBackButton,IonButtons } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './start.css';
import { chevronForward} from 'ionicons/icons';
import { caretBack} from 'ionicons/icons';
import { useLocation } from 'react-router-dom';
import { UIStore } from "./UIstore";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const Smart: React.FC = () => {
  const stato = UIStore.useState(s => s);

  function dimens() {
    UIStore.update(s => {
      s.termine = true;
    });
  }

  const location = useLocation();
  const fineParam = new URLSearchParams(location.search).get('fine');
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Triage Smart</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Triage Smart</IonTitle>
            <IonButtons slot="start">
              <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Triage SMART</h1>
          <TransformWrapper>
            <TransformComponent>
            <img  src="/assets/img/smart.png" />
            </TransformComponent>
          </TransformWrapper>
          {fineParam === '1' && (
            <IonButton fill="clear" size="large" onClick={() => dimens()} routerLink='/'>
              Termina<IonIcon slot="end" icon={chevronForward}></IonIcon>
            </IonButton>
          )}
          </div>
     
      </IonContent>
    </IonPage>
  );
};

export default Smart;
