import { Store } from "pullstate";

interface coinfoprops {
  loc: string,
}

interface IUIStore {
  ruolo: number;
  andata: boolean;
  target: boolean;
  termine: boolean;
  dimevento: boolean;
  pcoa: boolean;
  attpma: boolean;
  areeracc: boolean;
  triageing: boolean;
  alsnec: boolean;
  stabord: boolean;
  evacpaz: boolean;
  gestscena: boolean;
  coinfo:  Array<coinfoprops>;
}


export const UIStore = new Store<IUIStore>({
  ruolo: 0,
  andata: false,
  target: false,
  termine: false,
  dimevento: false,
  pcoa: false,
  attpma: false,
  areeracc: false,
  triageing: false,
  alsnec: false,
  stabord: false,
  evacpaz: false,
  gestscena: false,
  coinfo: [{loc: 'Via Roma, 1, 00100 Roma'}],
});