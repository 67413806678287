import './ExploreContainer.css';
import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,IonNavLink,IonNav, IonRouterLink } from '@ionic/react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { earth,pulse,medical } from 'ionicons/icons';

import Tab1 from '../pages/Tab1';




interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className="container">
      <img src="/assets/icon/ares.png"/>
      <IonGrid> 
        <IonRow>
          <IonCol>
          
            <a href="https://ares-odv.org/">
              <IonCard color="primary">
                <img  src="/assets/img/slide4.jpg" />
                <IonCardHeader>
                  <IonCardTitle><IonIcon icon={earth} size="large" color="primary"></IonIcon></IonCardTitle>
                  <IonCardSubtitle>Ares ODV</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  Visita il nostro portale.
                </IonCardContent>
              </IonCard>
            </a>

          </IonCol>
          <IonCol>
            
              <IonCard color="primary" routerLink='/ruolo' >
              <img  src="/assets/img/maxi.jpeg" />
                <IonCardHeader>
                  <IonCardTitle><IonIcon icon={pulse} size="large" color="primary"></IonIcon></IonCardTitle>
                  <IonCardSubtitle>Maxiemergenza</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  Guida all'intervento in Maxiemergenze.
                </IonCardContent>
              </IonCard>

          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard color="primary" routerLink='/Tab1' >
              <img  src="/assets/img/a.jpg" />
                <IonCardHeader>
                  <IonCardTitle><IonIcon icon={medical} size="large" color="primary"></IonIcon></IonCardTitle>
                    <IonCardSubtitle>Accesso Rapido</IonCardSubtitle>
                  </IonCardHeader>

                  <IonCardContent>
                    Triage S.T.A.R.T. e S.M.A.R.T.
                  </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
          
      </IonGrid>
    </div>
  );
};

export default ExploreContainer;
