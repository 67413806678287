import { IonContent, IonHeader, IonPage,IonButtons,IonBackButton, IonTitle,IonToggle, IonToolbar,IonButton,IonItem,IonLabel,IonInput,IonTextarea,IonIcon, IonRadio, IonRadioGroup } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import { chevronForward} from 'ionicons/icons';
import './Tab14.css';
import { caretBack} from 'ionicons/icons';
import { UIStore } from "./UIstore";

const Tab14: React.FC = () => {
  const stato = UIStore.useState(s => s);

  function dimens() {
    UIStore.update(s => {
      s.attpma= true;
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Attivazione PMA</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Criteri di Attivazione PMA (solo se tutti soddisfatti)</h1>
          <IonItem>
            <IonLabel>N° feriti gravi &gt; N° ALS disponibili</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Disponibilità del PMA sul posto entro 1 ora</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Esclusa la possibilità di rischi evolutivi</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <h1>Uno tra:</h1>
          <IonItem>
            <IonLabel>Area dell'evento vasta</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Terreno Accidentato</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Vittime Incastrate</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonButton fill="clear" onClick={() => dimens()} size="large" routerLink='/tab8'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab14;
