import React, { useState } from 'react';
import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar, IonButton, IonCheckbox,IonList,IonItem,IonLabel,IonIcon} from '@ionic/react';
import './ruolo.css';
import { UIStore } from "./UIstore";
import { chevronForward} from 'ionicons/icons';
import { caretBack} from 'ionicons/icons';
const Pianificazione: React.FC = () => {
  const stato = UIStore.useState(s => s);
  const [stat, setStat] = useState(0); 
  var pag1 = [
    "Il tuo ruolo è coordinare tutte le squadre in arrivo",
    "Nessuna squadra deve muoversi finchè il DSS non ti autorizza ad inviarle",
    "Chiedi al DSS il supporto di un team ALS per stabilizzare feriti con tempi lunghi di estricazione"
  ];
  var pag2 = [
    "Partecipa alla suddivisione del crash in settori con il DSS",
    "Raduna tutte le squadre che sopraggiungono",
    "Individua e assegna ad ogni settore del crash un responsabile triage",
    "Assegna ad ogni responsabile triage un numero aseguato di operatori e consegna il kit triage",
  ];
  var pag3 = [
    "Istruisci le squadre:",
    "Indossare i DPI",
    "Le squadre sopraggiunte con ambulanza: gli autisti restano a bordo del mezzo con le chiavi e vengono coordinati dal DTR.",
    "Ogni squadra viene inviata in un settore del CRASH, dove svolge triage senza trasportare pazienti",
    "Ogni settore ha un responsabile triage a cui riferire numero e codice dei pazienti. Il referente triage riferisce al DSR che riferisce al DSS",
    "Inviare chiunque cammina all'area verdi. Evacuare gialli e rossi al PMA in accordo col DSS",
  ];
  var pag4 = [
    "Valutare ogni paziente che non cammina compilando la scheda di mazi emergenza in circa 60 secondi o piegare la scheda in modo che sia evidente solo un colore",
    "Effettuare solo le manovre previste(Guedel, Disostruzione, Tamponamento emorragie)",
    "Non Effettuare RCP",
    "Iniziare l'evacuazione del settore in ordine di priorità secondo le direttive del DSR",
  ];

  var pagineinf = [pag1, pag2, pag3, pag4];
  
  var pag5 = [
    "Controla che il mezzo sia in sicurezza",
    "Partecipa alla ricognizione dell'evento individuando le vie di afflusso e le aree per lo stazionamento dei mezzi",
    "Partecipa alle operazioni di settorializzazione con il DSS e DSR",
    "In accordo con DSS organizza le aree destinate ai mezzi",
  ];
  var pag6 = [
    "Invia per la piccola Noria gli operatori ed i presidi/mezzi di trasporto come da richiesta del DSS",
    "Gestisci i mezzi tenendoti in costante comunicazione con gli autisti",
    "Rimani in contatto con il DSS proponendo mezzo disponibili per l'evacuazione",
    "Verifica la presenza degli autisti sui mezzi",
    "Controlla il corretto imbarco paziente/mezzo/destinazione secondo disposizione del DSS"
  ];
  var pagineaut = [pag5, pag6];
 
  var pagine = [];
  pagine[0] = pagineinf;
  pagine[1] = pagineinf;
  pagine[2] = pagineinf;
  pagine[3] = pagineaut;

  function avanti(fine = false){
    if(!fine){
      setStat(stat + 1);
    }
    else{
      setStat(0);
    }    
  }

 
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Painificazione</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Pianificazione</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          {pagine[stato.ruolo][stat].map((pagina, index) => (
            <h2 key={index}>{pagina}</h2>
          ))}

         
       
        {stat ===  pagine[stato.ruolo].length-1 ? (
            <IonButton fill="clear" size="large" onClick={() => avanti(true)} routerLink="/elenco">Continua<IonIcon slot="end" icon={chevronForward}></IonIcon></IonButton>
          ) : (
            <IonButton fill="clear" size="large" onClick={() => avanti()}>Continua<IonIcon slot="end" icon={chevronForward}></IonIcon></IonButton>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Pianificazione;