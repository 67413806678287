import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';
import { caretBack} from 'ionicons/icons';

const Tab1: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Consulta Triage</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Consulta Triage</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <IonButton shape="round" size="large" routerLink='/start'>S.T.A.R.T. sul crash</IonButton>
          <IonButton shape="round" size="large" routerLink='/smart'>S.M.A.R.T. per PMA</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
