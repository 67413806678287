import { IonContent, IonHeader, IonButtons, IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab7.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab7: React.FC = () => {
  const stato = UIStore.useState(s => s);

  function cambiaandata() {
    UIStore.update(s => {
      s.andata = true;
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
        {
            stato.ruolo == 1 ? ( 
              <h2>Verifica che tutti abbiano questa app</h2>
            ) : (
              <h2>Il tuo unico interlocutore è il DSS</h2>
            )
           
        }

        {
            stato.ruolo == 1 ? ( 
              <h2>Dotati di radio portatile</h2>
            ) : (
              null
            )
           
        }
        
          <h2>Vieta a tutti il contatto con i media</h2>
  
          <IonButton fill="clear" onClick={() => cambiaandata()} size="large" routerLink='/tab4'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab7;
