import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab21.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab21: React.FC = () => {
  const stato = UIStore.useState(s => s);
  function dimens() {
    UIStore.update(s => {
      s.evacpaz = true;
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Evacuazione Pazienti</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Ordine di evacuazione pazienti</h1>
          <h3>Informare il DSS su necessità di evacuazione pazienti dal PMA agli Ospedali(Grande Noria)</h3>
          <h2>Tempo medio evacuazione Rosso(T1): Entro 1 ora</h2>
          <h2>Tempo medio evacuazione Giallo(T2): Entro 4/6 ore</h2>
          <IonButton fill="clear" size="large" onClick={() => dimens()} routerLink='/tab18'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab21;
