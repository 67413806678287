import { IonContent, IonHeader, IonPage, IonButtons,IonBackButton, IonTitle, IonToolbar,IonButton,IonIcon,IonItem, IonLabel, IonList, IonToggle } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab11.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab11: React.FC = () => {
  const stato = UIStore.useState(s => s);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Istituzione PCOA</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Istituzione PCOA (punto di comando operativo avanzato)</h1>
          <h2>Cerca ed unisciti ai vertici di:</h2>
          <IonItem>
            <IonLabel>Vigili del fuoco</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Forze dell'Ordine</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Protezione Civile</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <h2>Presentati a loro come il DSS</h2>
          <h2>Suddividi con PCOA,DSR,DTR il CRASH in settori</h2>
          <h2>Raduna le squadre e chiedi autorizzazione ad avviarle</h2>
          <p>Solo quando i vigili del fuoco e le forze dell'ordine autorizzano l'inizio dei soccorsi procedi con i prossimi punti.</p>
          <IonButton fill="clear" size="large" routerLink='/tab12'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab11;
