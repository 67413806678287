import { IonContent, IonHeader, IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab20.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab20: React.FC = () => {
  const stato = UIStore.useState(s => s);
  function dimens() {
    UIStore.update(s => {
      s.stabord= true;
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Stabilizzazione PMA</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Ordine Trattamento nel PMA</h1>
          <h2>Rosso Avanzato  &gt; Rosso(T1)  &gt; Giallo(T2)  &gt; Verde(T3)  &gt; Blu(T4)</h2>
          <h2>Rosso(T1) tempo di stabilizzazione entro 23 minuti</h2>
          <h2>Giallo(T2) tempo di stabilizzazione entro 13 minuti</h2>
          <IonButton fill="clear" size="large" onClick={() => dimens()} routerLink='/tab18'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab20;
