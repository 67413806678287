import React, { useState } from 'react';
import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab3.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Ruoli: React.FC = () => {
  const stato = UIStore.useState(s => s);
  const [stat, setStat] = useState(0);
  function avanti(){
    setStat(stat + 1);
    console.log(stat);
  }
  var pag = "/tab4";
  var frase ="DIRETTORE dei SOCCORSI SANITARI (DSS):"
  var frase2 = "E' responsabile di: •Comando, •Controllo, •Comunicazione, •Sicurezza\nE' un medico dell'emergenza territoriale con esperienza e formazione adeguata, presente nella zona di operazioni e responsabile della gestione in loco di tutto il dispositivo di intervento sanitario.\nE' il medico della prima ALS fino all'arrivo di un eventuale medico esperto incaricato dalla CO118.\nFunzioni: frena l'istinto di soccorrere i feriti immediatamente, stima l'evento, dirige le operazioni sanitarie sul territorio, collabora con il DPMA, collabora con il responsabili tecnici dei Vigili del Fuoco e delle Forze di Polizia nel PCOA, aggiorna l'Autorità e i Media, stilando i comunicati; qualora necessario attiva Team di Supporto Psicologico."
  switch (stat) {
    case 1:
      var frase = "DIRETTORE SQUADRE DI RECUPERO (DSR):"
      var frase2 = "E' l'infermiere della prima ALS fino all'arrivo di un medico incaricato dal DSS. \nCompiti: coordina le operazioni di recupero, il triage sommario dei feriti, le manovre di supporto vitale e di immobilizzazione, supervisiona le operazioni di disincarcerazione e recupero complesso con il supporto dei Vigili del Fuoco, coordina le équipes di volontari che coadiuvano il personale sanitario, definisce le aree di raccolta dei feriti ed il percorso ottimale da seguire per raggiungerle."
      // code for case 1
      break;
    case 2:
      var frase = "DIRETTORE del TRASPORTO (DTR):"
      var frase2 = "E' l'autista della prima ALS fino all'arrivo di un altro operatore tecnico o infermiere incaricato dal DSS. \nFunzioni: gestisce i mezzi disponibili secondo caratteristiche e necessità dei pazienti; organizza la zona di stazionamento dei vettori di evacuazione controllando la presenza fissa degli autisti sui mezzi; organizza l'area di imbarco dei pazienti vicina al PMA; controlla le operazioni di trasferimento e di destinazione dei mezzi con la raccolta dei documenti relativi all'evacuazione, individua i luoghi più adatti per l'atterraggio di eventuali elicotteri."
      // code for case 2
      break;
    case 2:
      var frase = "DIRETTORE del PMA (DPMA):"
      var frase2 = "E' un medico del 118 che giunge sul target con il PMA, incaricato di coordinare le operazioni di triage, stabilizzazione ed evacuazione delle vittime nel PMA. Compila o fa compilare la scheda di triage, esamina e fa esaminare le vittime, assicurandone la sorveglianza, garantisce la stabilizzazione clinica dei feriti in base alla gravità, facilita l'opera di riconoscimento delle vittime. Si relaziona direttamente con il DSS."
      // code for case 2
      break;
    case 4:
      setStat(0);
      break;
    default:
        
      break;
  }
    
  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Spiegazione Ruoli</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Inizio</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>{frase}</h1>
          <p style={{whiteSpace: 'pre-line', textAlign: 'left' }}>{frase2}</p>
          {stat === 3 ? (
            <IonButton fill="clear" size="large" onClick={() => avanti()} routerLink="/ruolo">Continua<IonIcon slot="end" icon={chevronForward}></IonIcon></IonButton>
          ) : (
            <IonButton fill="clear" size="large" onClick={() => avanti()}>Continua<IonIcon slot="end" icon={chevronForward}></IonIcon></IonButton>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Ruoli;
