import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar,IonButton,IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab11.css';
import { chevronForward} from 'ionicons/icons';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab11: React.FC = () => {
  const stato = UIStore.useState(s => s);
  function cambiapcoa() {
    UIStore.update(s => {
      s.pcoa = true;
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>DTR</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <h1>Comunica al DTR</h1>
          <h2>Di indicare ai mezzi in arrivo dove e come parcheggiarsi</h2>
          <h2>Di indicare agli autisti di lasciare le chiavi inserite nel mezzo</h2>
          <h2>Di assistenre l'allestimento del PMA se attivato</h2>
          <IonButton fill="clear" onClick={() => cambiapcoa()} size="large" routerLink='/tab8'>
            Continua
            <IonIcon slot="end" icon={chevronForward}></IonIcon>
          </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab11;
