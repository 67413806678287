import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  IonBackButton,
  IonButtons
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle ,home,caretBack} from 'ionicons/icons';
import Home from './pages/home';
import Tab1 from './pages/Tab1';
import Tab3 from './pages/Tab3';
import Tab4 from './pages/Tab4';
import Tab5 from './pages/Tab5';
import Tab6 from './pages/Tab6';
import Tab7 from './pages/Tab7';
import Tab8 from './pages/Tab8';
import Tab9 from './pages/Tab9';
import Tab10 from './pages/Tab10';
import Tab11 from './pages/Tab11';
import Tab12 from './pages/Tab12';
import Tab13 from './pages/Tab13';
import Tab14 from './pages/Tab14';
import Tab15 from './pages/Tab15';
import Tab15B from './pages/Tab15B';
import Tab17 from './pages/Tab17';
import Tab18 from './pages/Tab18';
import Tab19 from './pages/Tab19';
import Tab20 from './pages/Tab20';
import Tab21 from './pages/Tab21';
import Tab22 from './pages/Tab22';
import ruolo from './pages/ruolo';
import ruoli from './pages/ruoli';
import start from './pages/start';
import smart from './pages/smart';
import Elenco from './pages/elenco';
import Pianificazione from './pages/pianificazione';
import Soccorsi from './pages/soccorsi';
import Assegna from './pages/assegna';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/tab1" component={Tab1} />
          <Route exact path="/tab3" component={Tab3} />
          <Route exact path="/tab4" component={Tab4} />
          <Route exact path="/tab5" component={Tab5} />
          <Route exact path="/tab6" component={Tab6} />
          <Route exact path="/tab7" component={Tab7} />
          <Route exact path="/tab8" component={Tab8} />
          <Route exact path="/tab9" component={Tab9} />
          <Route exact path="/tab10" component={Tab10} />
          <Route exact path="/tab11" component={Tab11} />
          <Route exact path="/tab12" component={Tab12} />
          <Route exact path="/tab13" component={Tab13} />
          <Route exact path="/tab14" component={Tab14} />
          <Route exact path="/tab15" component={Tab15} />
          <Route exact path="/tab15B" component={Tab15B} />
          <Route exact path="/tab17" component={Tab17} />
          <Route exact path="/tab18" component={Tab18} />
          <Route exact path="/tab19" component={Tab19} />
          <Route exact path="/tab20" component={Tab20} />
          <Route exact path="/tab21" component={Tab21} />
          <Route exact path="/tab22" component={Tab22} />
          <Route exact path="/ruolo" component={ruolo} />
          <Route exact path="/ruoli" component={ruoli} />
          <Route exact path="/start" component={start} />
          <Route exact path="/smart" component={smart} />
          <Route exact path="/pianificazione" component={Pianificazione} />
          <Route exact path="/elenco" component={Elenco} />
          <Route exact path="/soccorsi" component={Soccorsi} />
          <Route exact path="/assegna" component={Assegna} />
          <Route exact path="/" component={Home} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/">
            <IonIcon aria-hidden="true" icon={home} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
