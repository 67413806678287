import { IonContent, IonHeader,IonButtons,IonBackButton, IonPage, IonTitle, IonToolbar, IonButton, IonRow} from '@ionic/react';
import './Tab8.css';
import { UIStore } from "./UIstore";
import { caretBack} from 'ionicons/icons';
const Tab8: React.FC = () => {
  const stato = UIStore.useState(s => s);
  console.log(stato);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Stato</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" icon={caretBack}></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Stato</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">

              <IonButton shape="round" expand="full" size="large" color={stato.dimevento ? 'success' : 'light'} routerLink='/tab9'>1: Dimensionamento Evento</IonButton>
              <IonButton shape="round" expand="full" size="large" color={stato.pcoa ? 'success' : 'light'} routerLink='/tab11'>2: Istituzione PCOA</IonButton>
              <IonButton shape="round" expand="full" size="large" color={stato.areeracc ? 'success' : 'light'} routerLink='/tab15'>3: Identificazione Aree di Raccolta</IonButton>
              <IonButton shape="round" expand="full" size="large" color={stato.gestscena ? 'success' : 'light'} routerLink='/tab15B'>4: Gestione Scena</IonButton>
              <IonButton shape="round" expand="full" size="large" color={stato.attpma ? 'success' : 'light'} routerLink='/tab14'>5: Attivazione Eventuale PMA</IonButton>

          <h1>PMA ATTIVATO?</h1>
          <IonButton shape="round" size="large" routerLink='/Tab17'>SI</IonButton>
          <IonButton shape="round" size="large" routerLink='/Tab4'>NO</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab8;